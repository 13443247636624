<template>
  <!-- 
    画面: オプション一覧テーブル
    用途: オプション一覧カードを一覧表示する
   -->
  <div>
    <Draggable
      class="list-group"
      v-model="tableItems"
      @end="onSortEnd"
      v-bind="dragOptions"
    >
      <transition-group
        type="transition"
        name="flip-list"
      >
        <!-- #region Table -->
        <template v-for="tableItem in tableItems">
          <OptionListCard
            :key="tableItem.id"
            :cardItem="[tableItem]"
            :canShowCard="canShowTable"
            canShowTable
            class="border-white border rounded p-2 mb-4 with-drag-button "
          />
        </template>
        <!-- #endregion Table -->
      </transition-group>
    </Draggable>

    <!-- #region Alert -->
    <CAlert
      color="danger"
      class="mt-3 text-center"
      v-if="notFound"
    >
      {{$t("optionListPage.notFound")}}
    </CAlert>
    <!-- #endregion Alert -->

    <!-- #region Modal -->
    <!-- #endregion Modal -->

  </div>
</template>

<script>
import axios from "axios";
import OptionListCard from "./ListCard";

export default {
  name: "OptionListTable",

  components: {
    // RoomTypeListCard
    OptionListCard,
  },

  props: {},

  data() {
    return {
      //#region Flag

      loading: false,

      //#endregion Flag

      //#region Table

      uniqueId:this.$store.state.uniqueId,
      order: [],

      /** 一覧表示データ */
      tableItems: [],
      //#endregion Table

      //#region Response data

      response: [],

      //#endregion Response data
    };
  },

  computed: {
    /** 一覧表表示できるか？ */
    canShowTable() {
      // return !this.loading && !this.notFound;
      return true; // TODO: 開発用
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.response.length < 1;
    },
    /** drag and drop */
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  methods: {

        //#region Request
    reqGet() {
      const url = `/rest/hotel/${this.uniqueId}/option`;

      axios
        .get(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          const datas = resp.data.list;
          this.response = datas;
          this.pourTable(datas);
          this.createDisplayOrderIdAscList(datas);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = `${err.name} : ${err.message}`;
          this.PassError = err.response.status;
          this.loading = false;
        });
    },

    //#endregion Request

    /** 一覧表ソート(並び替え) */
    onSortEnd() {
      // 一覧表の表示順IDを昇順の値にする
      this.tableItems.forEach((a, i) => a.displayOrder = this.order[i]);
      this.reqPutDisplayOrder();
    },


    /** 表示順更新 */
    reqPutDisplayOrder() {
      const url = `/rest/hotel/option/order`; 
      let list = [];
      this.tableItems.forEach(a => list.push({ id: a.id, displayOrder: a.displayOrder }));
      const body = { list }; 
      axios
        .put(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log(resp.data);
          this.reqGet();
        })
        .catch((err) => {
          this.errorModal = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          console.log(err);
        });
    },
    //#endregion Request



    //#region Method

    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        this.tableItems.push({
          id: data.id,
          code: data.code,
          images: data.images,
          name: data.name,
          price: data.price,
          inventory: data.inventory,
          description: data.description,
          language: data.language,
          //TODO:開発用
          status: false,
          // status: data.status,
          displayOrder: data.displayOrder ?? data.id,
        });
      });
      console.log("pourTable")
      console.log(this.tableItems)
    },

    /**
     * 一覧表のIDリストを昇順ソートし、表示順IDリストとする
     * Note: reqGet()までリスト不変の想定
     * @param item
     */
    createDisplayOrderIdAscList(datas) {
      this.order = [];
      datas.forEach((a) => this.order.push(a.id));
      this.order.sort((a, b) => a - b);
      console.log("order")
      console.log(this.order)
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.tableItems = [];
    },

    /**  */
    createCardItems(tableItem) {
      const cardItems = new Array(tableItem);
      return cardItems;
    },

    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>
