<template>
  <!-- 
    画面: オプション一覧カード
    用途: オプション一覧の1件のデータを表示する
   -->
  <div>

    <CRow>
      <CCol md="1">
        <CImg
          class="mb-2"
          fluid
          v-if="!cardItem[0].images.length <= 0"
          :src="imgUrl+cardItem[0].images[0].fileName"
        />
      </CCol>
      <CCol md="10">
        <!-- #region Table -->
        <CDataTable
          border
          hover
          size="sm"
          :fields="tableFields"
          :items="cardItem"
          v-if="canShowTable"
        >

          <td
            slot="name"
            slot-scope="{ item }"
          >
            <span class="ml-2 text-left">{{ item.name }}</span>
          </td>
          <td
            slot="price"
            slot-scope="{ item }"
            class="text-center"
          >
            <span class="ml-2 text-left">{{ item.price }}</span>
          </td>
          <td
            slot="inventory"
            slot-scope="{ item }"
            class="text-center"
          >
            <span class="ml-2 text-left">{{ item.inventory }}</span>
          </td>
          <td
            slot="status"
            slot-scope="{ item }"
            class="text-center p-0"
          >
            <CSwitch
              class="mt-1 swc sw-2"
              color="info"
              data-on="On"
              data-off="Off"
              :labelOn='$t("common.active")'
              :labelOff='$t("common.inActive")'
              :checked="item.status"
              @update:change="onStatusChanged()"
            />
          </td>
          <template #action="{ item }">
            <td class="p-0 text-center">
              <CButton
                class="mt-1 px-3"
                color="info"
                square
                size="sm"
                @click="onEditClicked(item.id)"
              ><i class="icon cil-pencil mr-1"></i>{{ $t("optionListPage.editButton") }}</CButton>
            </td>
          </template>
        </CDataTable>
        <!-- #endregion Table -->

      </CCol>
    </CRow>

  </div>
</template>

<script>
import axios from "axios";
import i18n from "@/i18n";
import config from "@/config"
export default {
  name: "OptionListCard",

  props: {
    /** カード1件分データ */
    cardItem: {
      type: Array,
      required: true,
      // default: {}
    },

    /** カード表示可能か？ */
    canShowTable: {
      type: Boolean,
      required: true,
      default: true,
    },
  },

  data() {
    return {
      //#region Flag
      //#endregion Flag

      //#region Table
      imgUrl: config.IMAGE_URL,
      displayImgURL: "",
      uniqueId:this.$store.state.uniqueId,

      /** 一覧列名 */
      tableFields: [
        // {
        //   key: "img",
        //   label: `${i18n.t("optionListPage.img")}`,
        //   _classes: "text-left bg-light",
        // },
        {
          key: "name",
          label: `${i18n.t("optionListPage.name")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "price",
          label: `${i18n.t("optionListPage.price")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "inventory",
          label: `${i18n.t("optionListPage.inventory")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "status",
          label: `${i18n.t("optionListPage.status")}`,
          _classes: "text-center bg-light",
        },
        {
          key: "action",
          label: `${i18n.t("optionListPage.action")}`,
          _classes: "text-center bg-light",
        },
      ],

      //#region Response data
      //#endregion Response data
    };
  },


  methods: {
    //#region Event

    /** 有効無効切替 */
    onStatusChanged(item) {
      // TODO: 有効無効ステータス更新リクエスト
      item.status = this.toggleStatus(item.status);
      this.reqPutStatus();
    },

    /** 編集ボタン押下 */
    onEditClicked(optionId) {
      this.$router.push({
        path: `/operator/option/${optionId}/edit`,
      });
      console.log("onEditClicked")
      console.log(optionId)
    },

    //#endregion Event

    //#region Request

    /** 有効無効ステータス更新 */
    reqPutStatus(item) {
      this.loading = true;

      const url = `/rest/${this.uniqueId}/option/${item.id}/status`;
      const body = { status: item.status };

      axios
        .put(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.loading = false;
          this.reqGet();
        })
        .catch((err) => {
          this.loading = false;
          this.errorModal = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          console.log(err);
        });
    },

    //#endregion Request

    //#region Method


    //#endregion Method
  },

  beforeMount() {
  },
};
</script>
