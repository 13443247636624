var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"md":"1"}},[(!_vm.cardItem[0].images.length <= 0)?_c('CImg',{staticClass:"mb-2",attrs:{"fluid":"","src":_vm.imgUrl+_vm.cardItem[0].images[0].fileName}}):_vm._e()],1),_c('CCol',{attrs:{"md":"10"}},[(_vm.canShowTable)?_c('CDataTable',{attrs:{"border":"","hover":"","size":"sm","fields":_vm.tableFields,"items":_vm.cardItem},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.name))])])}},{key:"price",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.price))])])}},{key:"inventory",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('span',{staticClass:"ml-2 text-left"},[_vm._v(_vm._s(item.inventory))])])}},{key:"status",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center p-0"},[_c('CSwitch',{staticClass:"mt-1 swc sw-2",attrs:{"color":"info","data-on":"On","data-off":"Off","labelOn":_vm.$t("common.active"),"labelOff":_vm.$t("common.inActive"),"checked":item.status},on:{"update:change":function($event){return _vm.onStatusChanged()}}})],1)}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"p-0 text-center"},[_c('CButton',{staticClass:"mt-1 px-3",attrs:{"color":"info","square":"","size":"sm"},on:{"click":function($event){return _vm.onEditClicked(item.id)}}},[_c('i',{staticClass:"icon cil-pencil mr-1"}),_vm._v(_vm._s(_vm.$t("optionListPage.editButton")))])],1)]}}],null,false,2726014528)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }