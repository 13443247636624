<template>
  <!-- 
    画面: オプション一覧
    用途: オプションの一覧を参照する
   -->
  <div>

    <!-- #region TODO -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="mr-3">{{ $t("optionListPage.pageTitle") }}</strong>
            
            <CButton
              color="info"
              @click="onCreateClicked()"
            >{{ $t("common.create") }}</CButton>
          </CCardHeader>
          <CCardBody>
            <OptionListTable />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion TODO -->

  </div>
</template>

<script>
import OptionListTable from "./components/ListTable";

export default {

  name: "OptionList",

  components: {
    OptionListTable
    // ListTable
  },

  methods: {

    //#region Event

    /** 新規作成ボタン押下 */
    onCreateClicked() {
      this.$router.push({
        path: `/operator/option/create`
      });
    },

    /** 並び替え操作 */

    //#endregion Event

  }
}
</script>
